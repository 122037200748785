<template lang="pug">
  v-data-table.clickable(
    :headers="headers"
    :loading="areInvoicesPending"
    no-data-text="No Invoices Found"
    :options.sync="options"
    :server-items-length="haveInvoicesLoaded ? paginationData.default.mostRecent.total : 0"
    :items="invoices"
    @click:row="(item) => $router.push({name: 'invoice', params: { invoiceId: item.id }})"
    :footer-props="{ itemsPerPageOptions: [5,10,15] }"
  )
    template(#body.prepend)
      tr
        td(colspan="100")
          v-btn(
            color="primary"
            block
            :to="{ name: 'edit-invoice', params: { invoiceId: 'new' }, query: { shopId }}"
          )
            v-icon(
              left
            ) mdi-plus
            | Add Cost Recovery Invoice

    template(v-slot:item.invoiceNumber="{ item: invoice }") {{ invoice.invoiceNumber }}
    template(v-slot:item.date="{ item: invoice }") {{invoice.formattedDate}}
    template(v-slot:item.listPriceTotal="{ item: invoice }")
      span(v-if="invoice.listPriceTotal") ${{ Number(invoice.listPriceTotal).toFixed(2) | round(2) }}
    template(v-slot:item.costPriceTotal="{ item: invoice }")
      span(v-if="invoice.costPriceTotal") ${{ Number(invoice.costPriceTotal).toFixed(2) | round(2) }}
    template(
      v-slot:item.tech_id="{ item: invoice }"
    )
      span(v-if="invoice.tech") {{ invoice.roNumber }}
    template(v-slot:item.actions="{ item: invoice }")
      v-btn.px-0.mr-2(
        min-width="40px"
        @click.stop.prevent="deleteInvoice(invoice)"
        color="white"
        elevation='0'
      )
        v-icon mdi-delete
      v-btn.pa-0(
        min-width="40px"
        @click.stop.prevent="(item) => $router.push({name: 'edit-invoice', params: { invoiceId: invoice.id }})"
        color="white"
        elevation='0'
      )
        v-icon.ma-0(
        ) mdi-pencil
</template>

<script>
import { useFind } from 'feathers-vuex'
import { computed, ref } from '@vue/composition-api'

export default {
  name: 'InvoicesTable',
  props: {
    query: {
      type: Object,
      required: false,
      default: () => {
        return {}
      }
    },
    headers: {
      type: Array,
      required: false,
      default: () => {
        return [
          {
            text: 'Invoice Number',
            value: 'invoiceNumber'
          },
          {
            text: 'Date',
            value: 'date'
          },
          {
            text: 'Status',
            value: 'status'
          },
          {
            text: 'List Total',
            value: 'listPriceTotal'
          },
          {
            text: 'Cost Total',
            value: 'costPriceTotal'
          },
          {
            text: 'RO Number',
            value: 'roNumber'
          },
          {
            text: 'Actions',
            value: 'actions'
          }
        ]
      }
    },
    shopId: {
      type: String,
      required: false
    }
  },
  setup (props, context) {
    const { Invoice } = context.root.$FeathersVuex.api

    // Setups up a reference that the search can v-model
    const search = ref(null)

    // Basic starter datatable attributes
    const options = ref({
      page: 1,
      itemsPerPage: 10,
      sortBy: ['date'],
      sortDesc: [true]
    })

    // Compute the sort based on various datatable properties
    const sortBy = computed(() => {
      var obj = {}
      if (options.value.sortBy && options.value.sortBy.length) {
        obj[options.value.sortBy[0]] = options.value.sortDesc[0] ? -1 : 1
      }
      return obj
    })

    const limit = computed(() => {
      if (options.value.itemsPerPage !== -1) {
        return options.value.itemsPerPage
      } else {
        return 999
      }
    })

    const queryObj = computed(() => {
      const returnQuery = {
        ...props.query,
        $limit: limit.value,
        $skip: options.value.itemsPerPage * (options.value.page - 1),
        $sort: sortBy.value
      }

      // Add a search term if it's not null, can $or multiple keys
      if (search.value !== null && search.value !== '') {
        returnQuery.$or = [
          {
            name: {
              $regex: search.value,
              $options: 'gi'
            }
          },
          {
            state: {
              $regex: search.value,
              $options: 'gi'
            }
          }
        ]
      }

      return returnQuery
    })

    // Construct the LOCAL params, these search the store
    const params = computed(() => {
      return { query: queryObj.value }
    })

    // Construct the fetch Params, these actually query the API, very confusing, I know
    const fetchParams = computed(() => {
      return { query: { ...queryObj.value, $eager: 'tech' } }
    })

    // Do the actual querying, be sure to remember to paginate
    const { items: invoices, isPending: areInvoicesPending, paginationData, haveLoaded: haveInvoicesLoaded } = useFind({
      model: Invoice,
      params,
      fetchParams,
      paginate: true
    })

    return {
      search,
      options,
      sortBy,
      limit,
      invoices,
      queryObj,
      params,
      fetchParams,
      areInvoicesPending,
      haveInvoicesLoaded,
      paginationData
    }
  },
  methods: {
    async deleteInvoice (invoice) {
      try {
        const message = 'Are you sure you want to delete this invoice?'
        const isYes = await this.$confirm(message, { title: 'Warning' })

        if (isYes) {
          await invoice.remove(invoice.id)

          this.$snackSuccess('Invoice Deleted')
        }
        if (this.user.isAdmin) {
          this.$router.push({ name: 'invoices' })
        } else if (this.user.isRegionAdmin) {
          this.$router.push({ name: 'region', params: { regionId: this.user.region_id } })
        }
      } catch (error) {
        this.$logError(error)
      }
    }
  },
  data: () => ({

  })
}
</script>
