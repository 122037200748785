<template lang="pug">
  v-card.mb-16(
    outlined
  )
    v-card-title
      v-layout(
        flex
      )
        h3.grey--text.text--darken-2 Cost Recovery Invoices
    InvoicesTable(
      :query="{ shop_id: shopId }"
      :shopId="shopId"
    )

</template>

<script>
import InvoicesTable from '@/components/tables/InvoicesTable'

export default {
  name: 'Shop',
  components: {
    InvoicesTable
  },
  props: {
    shopId: {
      type: String,
      required: true
    }
  },
  data: () => ({

  })
}
</script>
