var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"clickable",attrs:{"headers":_vm.headers,"loading":_vm.areInvoicesPending,"no-data-text":"No Invoices Found","options":_vm.options,"server-items-length":_vm.haveInvoicesLoaded ? _vm.paginationData.default.mostRecent.total : 0,"items":_vm.invoices,"footer-props":{ itemsPerPageOptions: [5,10,15] }},on:{"update:options":function($event){_vm.options=$event},"click:row":function (item) { return _vm.$router.push({name: 'invoice', params: { invoiceId: item.id }}); }},scopedSlots:_vm._u([{key:"body.prepend",fn:function(){return [_c('tr',[_c('td',{attrs:{"colspan":"100"}},[_c('v-btn',{attrs:{"color":"primary","block":"","to":{ name: 'edit-invoice', params: { invoiceId: 'new' }, query: { shopId: _vm.shopId }}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v("Add Cost Recovery Invoice")],1)],1)])]},proxy:true},{key:"item.invoiceNumber",fn:function(ref){
var invoice = ref.item;
return [_vm._v(_vm._s(invoice.invoiceNumber))]}},{key:"item.date",fn:function(ref){
var invoice = ref.item;
return [_vm._v(_vm._s(invoice.formattedDate))]}},{key:"item.listPriceTotal",fn:function(ref){
var invoice = ref.item;
return [(invoice.listPriceTotal)?_c('span',[_vm._v("$"+_vm._s(_vm._f("round")(Number(invoice.listPriceTotal).toFixed(2),2)))]):_vm._e()]}},{key:"item.costPriceTotal",fn:function(ref){
var invoice = ref.item;
return [(invoice.costPriceTotal)?_c('span',[_vm._v("$"+_vm._s(_vm._f("round")(Number(invoice.costPriceTotal).toFixed(2),2)))]):_vm._e()]}},{key:"item.tech_id",fn:function(ref){
var invoice = ref.item;
return [(invoice.tech)?_c('span',[_vm._v(_vm._s(invoice.roNumber))]):_vm._e()]}},{key:"item.actions",fn:function(ref){
var invoice = ref.item;
return [_c('v-btn',{staticClass:"px-0 mr-2",attrs:{"min-width":"40px","color":"white","elevation":"0"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.deleteInvoice(invoice)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1),_c('v-btn',{staticClass:"pa-0",attrs:{"min-width":"40px","color":"white","elevation":"0"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return (function (item) { return _vm.$router.push({name: 'edit-invoice', params: { invoiceId: invoice.id }}); }).apply(null, arguments)}}},[_c('v-icon',{staticClass:"ma-0"},[_vm._v("mdi-pencil")])],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }